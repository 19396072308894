import createPersistedState from "vuex-persistedstate";
import { createStore } from 'vuex'
const modulesFiles:any = import.meta.globEager('./modules/*.js')
const mymodules = Object.keys(modulesFiles).reduce((modules:any, modulePath:string) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/modules\/(.*)\.\w+$/, '$1')
  const value = modulesFiles[modulePath]
  modules[moduleName] = value.default
  return modules
}, {})
//导入日志插件
import { createLogger } from 'vuex'
import * as getters from './getters'
// import { arrayRemove } from 'vue-fant-base-plus'

const debug = !(import.meta.env.VITE_NODE_ENV === 'production')
const store = createStore({
    modules: {
        ...mymodules
    },
    state: {
        version:{},
        picVersion:{},
        //判断是否在进行刷新token操作
        isRefreshing:false,
        navPath:{},
        configTypes:{}
    },
    getters,
    mutations: {
        setConfigTypes(state, types){
            state.configTypes = types
        },
        
        setVersion(state, version){
            let picVersion = {} as Record<string, any>
            for(let [key, value] of Object.entries(version)){
                picVersion[key] = debug?'':'/v'+ value
            }
            state.version = version
            state.picVersion = picVersion
            if (typeof window !== 'undefined') {
                sessionStorage.setItem("version", JSON.stringify(state.version))
            }
        },
        setIsRefreshing(state, isRefreshing){
            state.isRefreshing = isRefreshing;
        },
        clearNavPath(state:any){
            for(let key in state.navPath){
                if(state.navPath[key] instanceof Array && state.navPath[key][0]){
                    state.navPath[key] = [state.navPath[key][0]];
                }
            }
        },
        setNavPath(state:any, navPath){
            state.navPath[navPath.name] = navPath.value;
        }
    },
    strict: debug,//严格模式，发布的时候自动取消严格模式
    plugins: debug ? [
        createLogger(),
        createPersistedState({
            storage: window.sessionStorage,
            paths:['version', 'picVersion', 'navPath', 'currentTab', 'user','menu','message','appStore', 'tabs.keepAliveList']
        })
    ] : [
        createPersistedState({
            storage: window.sessionStorage,
            paths:['version', 'picVersion', 'navPath', 'currentTab', 'user','menu','message','appStore', 'tabs.keepAliveList']
        })
    ]
})


export default store;