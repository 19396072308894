const directives = {};

directives.install = function(Vue) {
	Vue.directive('to', {
		beforeMount(el, binding, vnode) {
			function clickHandler(e) {
				// 这里判断点击的元素是否是本身，是本身，则返回
				if (!el.contains(e.target)) {
					return false;
				}
				if (window.$vueEl.$router && (typeof binding.value === 'string' || typeof binding.value === 'object')) {
					window.$vueEl.$router.push(binding.value);
				}
			}
			// 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
			el.__vueRouteTo__ = clickHandler;
			document.addEventListener('click', clickHandler);
		},
		updated(el, binding, vnode) {
			function clickHandler(e) {
				// 这里判断点击的元素是否是本身，是本身，则返回
				if (!el.contains(e.target)) {
					return false;
				}
				if (window.$vueEl.$router && (typeof binding.value === 'string' || typeof binding.value === 'object')) {
					window.$vueEl.$router.push(binding.value);
				}
			}
			document.removeEventListener('click', el.__vueRouteTo__);
			// 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
			el.__vueRouteTo__ = clickHandler;
			document.addEventListener('click', clickHandler);
		},
		unmounted(el, binding) {
			// 解除事件监听
			document.removeEventListener('click', el.__vueRouteTo__);
			delete el.__vueRouteTo__;
		},
	});

	// v-repeatClick:[foo]="10000"  data:{foo:'ad'} method:{this.foo=Symbol()}
	Vue.directive('repeatClick', {
		beforeMount(el, binding, vnode) {
			let time = binding.value instanceof Object ? binding.value.time : binding.value;

			function handlerClick(e) {
				if (!el.contains(e.target) || el.disabled) {
					return false;
				}
				el.disabled = true;
				el.classList.add('is-disabled');

				el.__timer__ = setTimeout(() => {
					el.disabled = false;
					el.classList.remove('is-disabled');
				}, time || 2000);
			}
			el.__vueClick__ = handlerClick;
			el.__arg__ = binding.arg;
			document.addEventListener('click', handlerClick, false);
		},
		updated(el, binding, vnode) {
			if (el.__arg__ !== binding.arg) {
				clearTimeout(el.__timer__);
				el.__timer__ = setTimeout(() => {
					el.disabled = false;
					el.classList.remove('is-disabled');
				}, 0);
			}
		},
		unmounted(el, binding) {
			document.removeEventListener('click', el.__vueClick__);
			delete el.__vueClick__;
			delete el.__arg__;
			delete el.__timer__;
		}
	});
	Vue.directive('tableScroll', {
		beforeMount: function(el, binding, vnode, oldVnode) {
			let cb_name = binding.expression;
			let cb = vnode.context[cb_name];
			let wrapper = el.querySelector('.el-table__body-wrapper');
			wrapper.__handler__ = function() {
				if (wrapper.offsetHeight + wrapper.scrollTop >= wrapper.scrollHeight) {
					cb && cb();
				}
			}
			wrapper.addEventListener('scroll', wrapper.__handler__)
		},
		unmounted: function(el, binding, vnode, oldVnode) {
			let wrapper = el.querySelector('.el-table__body-wrapper');
			wrapper.removeEventListener('scroll', wrapper.__handler__);
			delete wrapper.__handler__;
		}
	});
	Vue.directive('clickOutSide',{
		beforeMount (el, binding, vnode) {
		    function documentHandler (e) {
		        if (el.contains(e.target)) {
		            return false;
		        }
		        if (binding.expression) {
		            binding.value(e);
		        }
		    }
		    el.__vueClickOutside__ = documentHandler;
		    document.addEventListener('click', documentHandler);
		},
		unmounted (el, binding) {
		    document.removeEventListener('click', el.__vueClickOutside__);
		    delete el.__vueClickOutside__;
		}
	});	
}

export default directives;
