/**
 * 埋点的参数类型
 */
interface TrackConfig {
    /**
     * 响应头部的Accept
     */
    act: string,
    /**
     * 响应头部的content-type 
     */
    ct: string,
    /**
     * 当前请求的调用方法类型 
     */
    m: string,
    /**
     * 当前请求的参数 
     */
    p: string,
    /**
     * 当前页面的url 
     */
    referer: string,
    /**
     * 请求时的时间戳，ms 
     */
    reqt: number,
    /**
     * 随机数 
     */
    rnd: number,
    /**
     * 请求用时，ms 
     */
    rt: number,
    /**
     * 请求返回的状态码 
     */
    sc: number,
    /**
     * 当前请求的url 
     */
    u: string,
    /**
     * 版本 
     */
    v: string
}

/**
 * 隐藏密码接口的参数
 * @param config axios、ajax拦截器返回的参数
 */
function hidePassword(config:Record<string, any>){
    /* 需要隐藏参数的接口集合 */
    const list = [ '/web/admin/sysman/adminuserlogin/changePassword', '/web/common/sysman/user/changePassword' ]
    let r = false
    for(let i in list){
        if(config.url.indexOf(list[i])!=-1){
            r = true
            break;
        }
    }
    if(r){
        config.data = typeof config.data == 'string'?(JSON.parse(config.data) || {}):config.data
        for(let i in config.data){
            if(typeof config.data[i] == 'string'){
                config.data[i] = config.data[i].replace(/(\S){1}/g, '*')
            }
        }
    }
}

/**
 * 过滤需要埋点的请求后，再进行埋点调用
 * @param response axios拦截器返回的响应参数
 * @returns 
 */
export function axiosTrackingHttpApi(response:Record<string, any>){
    if(!(window as any).$mainStore){
        return ;
    }
    // 过滤埋点请求
    if(response.config.url.indexOf('/tracking/')!=-1){
        return;
    }
    hidePassword(response.config)
    let params = response.request.responseURL.split('?')[1] || (typeof response.config.data == 'object'?JSON.stringify(response.config.data || {}):response.config.data) || ''
    /* 上传文件接口不需要把参数传入 */
    if(response.config.url.indexOf('/file/')!=-1 && response.config.method == 'post'){
        params = null
    }
    const reqt = new Date((response.config as any).date).getTime()
    const now = new Date().getTime()
    const config = {
        "act": response.config.headers.Accept || response.config.headers.common.Accept,
        "ct": response.headers['content-type'],
        "m": response.config.method,
        "p": params,
        "referer": location.origin+location.pathname,
        "reqt": reqt,
        "rnd": now,
        "rt": now - reqt,
        "sc": response.status,
        "u": response.config.url,
        "v": "1.0.0"
    } as TrackConfig;
    (window as any).$mainStore.dispatch('trackingHttpApi', config)
}

/**
 * 过滤需要埋点的请求后，再进行埋点调用
 * @param response ajax拦截器返回的响应参数
 * @returns 
 */
export function ajaxTrackingHttpApi(response:Record<string, any>){
    if(!(window as any).$mainStore){
        return ;
    }
    // 过滤埋点请求
    if(response.config.url.indexOf('/tracking/')!=-1){
        return;
    }
    hidePassword(response.config)
    let params = response.config.url.split('?')[1] || (typeof response.config.data == 'object'?JSON.stringify(response.config.data || {}):response.config.data) || ''
    /* 上传文件接口不需要把参数传入 */
    if(response.config.url.indexOf('/file/')!=-1 && response.config.method == 'post'){
        params = ''
    }
    const reqt = new Date(response.requestDate).getTime()
    const config = {
        /* vue-fant-base-plus的ajax方法暂时只支持此accept */
        "act": 'application/json, text/plain, */*',
        "ct": response.headers['content-type'],
        "m": response.config.method,
        "p": params,
        "referer": location.origin+location.pathname,
        "reqt": reqt,
        "rnd": new Date().getTime(),
        "rt": new Date(response.date).getTime() - reqt,
        "sc": response.status,
        "u": response.config.url,
        "v": "1.0.0"
    } as TrackConfig;
    (window as any).$mainStore.dispatch('trackingHttpApi', config)
}