<template>
  <div
    class="overflow-hidden"
    :style="{
      borderRadius: borderRadius?borderRadius:(isCircular ? '50%' : '0'),
      width: curWidth,
      height: curHeight,
      cursor: preview && !error ? 'pointer' : ''
    }"
  >
    <img class="img-box" :preview="preview"  @click="openPreviewFun" :src="`${imgSrc}/size/${thumbnailWidth}/${thumbnailHeight}`" :large="imgSrc" @error="onError" :style="{width:width,height:height}" alt="" v-if="isThumbnail"/>
    <img
      v-else
      v-show="showImage && (avatar || (!avatar && !error))"
      ref="imageRef"
      :style="{width: width,height: height,objectFit: fit}"
      :src="imgSrc"
      :preview="preview"
      @error="onError"
      @load="load"
      @click="openPreviewFun"
      class="img-box"
    />
    <div class="img-error" v-if="showImage && !avatar && error">
      <i
        class="el-icon-picture-outline"
        :style="{ fontSize: curWidth.indexOf('%') == -1 ? `calc(${curWidth} / 2)` : '' }"
      ></i>
    </div>
    <span v-if="!showImage" @click="open">
      <slot />
    </span>
  </div>
  <el-image-viewer 
    v-if="showImageViewer" 
    :teleported="true" 
    :hide-on-click-modal ="true" 
    :url-list="[imgSrc]" 
    @close="showImageViewer = false" 
  />
</template>

<script setup lang="ts">
import defaultHeader from '@/assets/images/defaultHeader.png';
import { BASE_URL } from '@/utils/logRequest';

const emit = defineEmits(['error']);

const imageRef = ref<HTMLInputElement | null>(null);

interface Props {
  imgId: string
  fit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down'
  width?: string | number
  height?: string | number
  avatar?: boolean
  isCircular?: boolean
  borderRadius?:string
  type?: string
  preview?: boolean // 为true时，点击会弹预览
  showImage?: boolean
  files?: string[]
  firmId?: string
  fileType?:'firmfile'|'firmbizfile'|'userfile'
  isThumbnail?:boolean,
  thumbnailWidth?:string
  thumbnailHeight?:string
}

const props = withDefaults(defineProps<Props>(), {
  fit: 'contain',
  width: '100%',
  height: '100%',
  avatar: false,
  isCircular: false,
  imgId: '',
  type: '',
  preview: false,
  showImage: true,
  files: function() {
    return []
  },
  fileType:'firmfile',
  firmId: '',
  isThumbnail:false,//是否适用缩略图的
  thumbnailWidth:'100',
  thumbnailHeight:'100'
});

const error = ref(false);
const showImageViewer = ref(false);

const imgSrc = computed(() => {
  error.value = false;
  imageRef.value?.removeAttribute('__error');
  return props.imgId ? `${BASE_URL}/web/file/${props.fileType}/${props.imgId}` : '';
});

const curWidth = computed(() => {
  return typeof props.width === 'number' ? props.width + 'px' : props.width;
});

const curHeight = computed(() => {
  return typeof props.height === 'number' ? props.height + 'px' : props.height;
});

const onError = () => {
  if (imageRef.value) {
    imageRef.value.src = defaultHeader;
  }
  error.value = true;
  emit('error');
}

const open = () => {
  if (imageRef.value) {
    imageRef.value.getElementsByTagName('img')[0].click();
  }
}

const load = (e: Event) => {
  const target = e.target as HTMLImageElement;
  if (target.getAttribute('__error') !== '1') {
    error.value = false;
  }
}

const openPreviewFun = () => {
  if (props.preview === true && props.imgId) {
    showImageViewer.value = true;
  }
}
</script>

<style scoped lang="scss">
.__img {
  cursor: pointer;
}
.img-error {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  height: 100%;
  i {
    font-size: 30px;
    color: #666;
  }
}
.img-box{
  display: block
}
</style>
