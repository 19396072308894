import store from "@/store";
import { Router } from 'vue-router'
import router from '@/router'
import { loadRoutesAndGetApps } from '@/boot/beforeLoad'
import { staging } from "@/boot/beforeLoad";
import { deepCopy, serialize, hasAuth } from 'vue-fant-base-plus'
import tabs from '@/boot/qiankun/tabs'
import { getTabsList } from '@/store/modules/tabs';
import devApps from '@/boot/devApps'
// import { registerApps } from '@/apps'

/* 主应用白名单 */
const whiteList = ['login', 'adminLogin', 'register', 'retrievePassword', 'status', 'transit', 'loading', 'staffBind', 'agreement']

// /**
//  * 判断是否第一次加载到系统的叶子页面 
//  * @param {*} to 跳转的目的路由
//  * @param {*} from 上一层经过的路由
//  * @returns 
//  */
// const isReload = (to:any, from:any)=>{
//     return from.path == '/' && from.matched.length == 0 && to.name != 'transit'
// }

export function addRoute() {
    const token = (store.state as any).user.userId
    if (!token) {
        return;
    }
    /* 根据角色类型动态添加首页路由 */
    if ((store as any).state.user.current.roleTypeCode == 'ADMIN') {
        router.addRoute('layout', {
            path: '',
            name: 'index',
            children: [],
            meta: {
                title: '我的首页',
                resident: true,
                icon: 'el-icon-fant1-xuexiao2'
            },
            component: () => import('@/views/platform/empty.vue'),
        })
    } else if ((store as any).state.user.current.roleTypeCode == 'STAFF') {
        router.addRoute('layout', {
            path: '',
            name: 'index',
            children: [],
            meta: {
                title: '我的首页',
                resident: true,
                icon: 'el-icon-fant1-xuexiao2'
            },
            component: () => import('@/views/platform/index.vue'),
        })
    } else if ((store as any).state.user.current.roleTypeCode == 'PARTYMEMBER') {
        router.addRoute('layout', {
            path: '',
            name: 'index',
            children: [],
            meta: {
                title: '我的首页',
                resident: true,
                icon: 'el-icon-fant1-xuexiao2'
            },
            component: () => import('@/views/platform/partyMember/index.vue'),
        })
    }
}

export function routerHooks(router: Router) {
    if (sessionStorage.getItem('__fool_subapps') && (store.state as any).user.userId) {
        // const subapps = JSON.parse(sessionStorage.getItem('__fool_subapps') || '{}')
        /* 非登录情况，重新加载路由 */
        loadRoutesAndGetApps(router)
    }
    if (getTabsList().length) {
        store.commit('tabs/SET_TABS_LIST', getTabsList())
    }
    addRoute()
    router.beforeEach((to, from, next) => {
        if (from.path == '/' && to.path.indexOf((store.state as any).appStore.route.name) == -1 && to.path.indexOf((store.state as any).appStore.route.system) == -1) {
            store.commit('appStore/RESET_ROUTE')
        }
        if (from.path !== '/' && (window as any).$ftTracking) {
            (window as any).$ftTracking.onVueLeaveRoute()
        }
        /* 准生产环境下，根据url的传值设定子系统的加载地址 */
        if (staging) {
            let devAppsConfig = {} as Record<string, any>
            try{
                devAppsConfig = JSON.parse(sessionStorage.__fool_devAppsConfig)
            }catch(err){
                devAppsConfig = {}
            }
            let query = deepCopy(to.query) || {}
            let changekey = false
            for(let i in query){
                let cur = i.match(/__fool_(\S*)_host/)
                if(cur && cur[1]){
                    devAppsConfig[cur[1]] = query[i]
                    changekey = true
                    delete query[i]
                }
            }
            devAppsConfig && sessionStorage.setItem('__fool_devAppsConfig', JSON.stringify(devAppsConfig))
            if(changekey){
                location.replace(location.origin+location.pathname+'?'+serialize(query))
                return ;
            }
        }
        const token = (store.state as any).user.userId
        /* 微应用白名单路径 */
        // const microApps = sessionStorage.getItem('__fool_microApps')?JSON.parse(sessionStorage.getItem('__fool_microApps') || '[]'):[]
        if (whiteList.includes(String(to.name))) {
            next()
        } else if (token) {
            if (to.name) { // 有 name 属性，说明是主应用的路由
                if (to.name == 'index') {
                    /* 如果选中了主页，取消头部导航栏的模块选择 */
                    store.dispatch('setItem', {})
                    store.commit('SET_SELECT_ITEM', {})
                }
                if (Object.keys(devApps).includes(String(to.name))) {
                    let nameStr = to.params.pathMatch;
                    let arr = String(to.params.pathMatch).split(".");
                    if(arr.length>2){
                        arr = arr.slice(0,2)
                        nameStr = arr.join(".")
                    }
                    
                    if (hasAuth(`${String(to.name)}.${to.params.pathMatch}.enter`) || hasAuth(`${String(to.name)}.${nameStr}.enter`) ) {
                        next()
                    } else {
                        next({ name: 'index', replace: true })
                    }
                } else {
                    next()
                }
            } else {
                next({ path: '/status/404' })
            }
        } else {
            const roleTypeCode = sessionStorage.getItem('roleTypeCode')
            if (roleTypeCode == 'ADMIN') {
                next({ name: 'adminLogin', replace: true })
            } else {
                next({ name: 'login', replace: true })
            }
        }
    })

    router.afterEach((to, from) => {
        const token = (store.state as any).user.userId
        // if(token && isReload(to, from)){
        //     const apps = JSON.parse(sessionStorage.getItem('__fool_microApps') || '[]')
        //     /* 非登录情况，重新注册微应用 */
        //     apps?registerApps(apps):null
        // }
        if (token) {
            if (to.name && !whiteList.includes(String(to.name))) {
                /* 刷新情况下，此时dom已经渲染完，调用当前路由的tab页加载 */
                tabs.openTab({
                    ...to
                })
            }
        }
        if (from.path !== '/' && (window as any).$ftTracking) {
            (window as any).$ftTracking.vueInit(location.origin + import.meta.env.BASE_URL + '/static/tracking.js')
        }
    })
}