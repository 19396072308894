export const connected = (state:any) => state.message.connected

export const unreadMsgCount = (state:any) => state.message.unreadMsgCount

export const messages = (state:any) => state.message.messages

export const is_collapse = (state:any) => state.menu.is_collapse

export const menuTree = (state:any) => state.menu.menuTree

export const menuItem = (state:any) => state.menu.item

export const menuSelectItem = (state:any) => state.menu.selectItem

export const breadcrumb = (state:any) => state.menu.breadcrumb

export const current = (state:any) => state.user.user

export const routeHistoryStack = (state:any) => state.menu.routeHistoryStack