import requestPlugin from '@/utils/request'
import plugins from '@/libs/plugins'
import FooltechSass from 'fant-microsass-web'
import { routerHooks } from '@/router/hooks'
import { loadConfig } from './beforeLoad'
import { bus } from 'vue-fant-base-plus'
import router from '@/router'
import store from '@/store'
import CryptoJS from 'crypto-js'
/* 登录页滑动验证需要用到 */
(window as any).CryptoJS = CryptoJS;


export default new class Entry{
    install(app:any){
        /* 加载各服务接口前缀等配置 */
        loadConfig(app);

        (window as any).$mainBus = bus
        
        /* 初始化插件 */
        plugins.install(app, router, store)
        
        app.use(requestPlugin)
        
        /* 应用通讯对象必须在路由钩子初始化前进行初始化 */
        app.use(FooltechSass, {router, store})
        /* 路由钩子 */
        routerHooks(router)
    }
}()