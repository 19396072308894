import axios from 'axios'
import { ElMessage } from 'element-plus'
// const PROD = import.meta.env.VITE_NODE_ENV === 'production'
/* 主模块服务前缀 */
/* 使用import.meta.env.PROD会有坑，判断会有错 */
export const BASE_URL = import.meta.env.VITE_NODE_ENV === 'production'?(window as any).$appConfig.BASE_API:import.meta.env.VITE_BASE_URL
export const BASEMSG_URL = import.meta.env.VITE_NODE_ENV === 'production'?(window as any).$appConfig.MSG_API:import.meta.env.VITE_BASE_URL

export const timeout = 180000

// 创建axios实例
const service = axios.create({
  baseURL: BASE_URL, // api的base_url
  timeout                  // 请求超时时间
});
// request拦截器
service.interceptors.request.use(
    config => {
        config.url = config.url;
        return config;
    },
    error => {
        return Promise.reject(error)
    }
);
// respone拦截器
service.interceptors.response.use(
  response => {
      return response.data;
  },
  error => {
      console.warn(error.message);
      ElMessage({
          showClose: true,
          message: "网络异常，请稍后重试",
          type: 'error',
          duration: 5 * 1000
      });
    return Promise.reject(error)
  }
)

export default service;
