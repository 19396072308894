import system from "@/api/login";
import { trackingHttpApi } from "@/api/tracking";
import { setToken, setName } from "@/utils/auth";
import router from "@/router";
import msg_ from "@/api/message";
import store from '@/store'
let msg = {};

if (typeof window !== "undefined") {
  msg = msg_;
}

const user = {
  state: {
    current:{},
    userId: "",
    name: "",
    loginInfo:{},
    identityList:[],
    // token: "",
    // refreshToken: "",
    schoolInfo: {},
    currentTerm:{}
  },

  mutations: {
    SET_LOGININFO(state, info){
      state.loginInfo = info
    },
    SET_USER: (state, user) => {
      state.user = user;
    },
    SET_USERID: (state, userId) => {
      state.userId = userId;
    },
    SET_NAME: (state, name) => {
      state.name = name;
      setName(name);
    },
    SET_CURRENT(state, current){
      state.current = current
      sessionStorage.setItem('current', JSON.stringify(current))
    },
    // SET_TOKEN: (state, token) => {
    //   state.token = token;
    //   if (window.$ftTracking) {
    //     window.$ftTracking.setToken(token);
    //   }
    //   setToken(token);
    // },
    // SET_REFTOKEN: (state, token) => {
    //   state.refreshToken = token;
    //   setRefToken(token);
    // },
    SET_SCHOOLINFO: (state, info) => {
      state.schoolInfo = info;
    },
    SET_CURRENTTERM: (state, info) => {
      state.currentTerm = info;
    },
    SET_IDENTITYLIST: (state, data) => {
      state.identityList = data;
    },
  },

  actions: {
    /**
     * 发起接口调用的数据埋点请求
     * @param {*} config 
     */
    trackingHttpApi({ state }, config){
      const menusbycode = JSON.parse(sessionStorage.getItem('__fool_menusbycode') || '{}') || {}
      const activeTab = store.state.tabs.activeTab
      config.bm = activeTab.meta && activeTab.meta.title?activeTab.meta.title:(activeTab.title || '')
      const key = activeTab.params && activeTab.params.pathMatch
      if(key){
        config.rid = menusbycode[key]?menusbycode[key].id:''
      }else{
        config.rid = ''
      }
      trackingHttpApi(config)
    },
    setUser: ({ commit }, user) => {
      commit("SET_USER", user);
    },
    setUserId: ({ commit }, userId) => {
      commit("SET_USERID", userId);
    },
    setSchoolInfo: ({ commit }, info) => {
      commit("SET_SCHOOLINFO", info);
    },
    setCurrentTerm: ({ commit }, info) => {
      commit("SET_CURRENTTERM", info);
    },
    // 登录
    Login({ commit }, userInfo) {
        const username = userInfo.username.trim();
        const password = userInfo.password.trim();
        const { captcha, captchaId, isPlatformOperate } = userInfo  
        return new Promise((resolve, reject) => {
            system.login(username, password, {captcha, captchaId, isPlatformOperate}).then(response => {
            const data = response;
            if(data.ok) {
                //保存在vuex
                commit('SET_NAME', data.data.userName);
                commit('SET_USERID', data.data.userId);
                commit('SET_LOGININFO', data.data)
                if (data.data.userType === 'ADMIN') {
                  commit('SET_CURRENT', {
                    ...data.data,
                    roleTypeCode: data.data.userType,
                    name: data.data.userName,
                    id: data.data.userId
                  })
                }
                localStorage.setItem('lastTokenTime',new Date().getTime())
            }
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // Refresh({ commit, state }, tokenJson) {
    //   return new Promise((resolve, reject) => {
    //     system
    //       .refresh({ oldToken: state.token, refreshToken: state.refreshToken })
    //       .then((response) => {
    //         const data = response;
    //         if (data.ok) {
    //           //保存在vuex
    //           //if(data.data !== state.token){
    //           let time = new Date().getTime();
    //           localStorage.setItem("lastTokenTime", time);
    //           if (window.frames.$vueEl) {
    //             window.frames.localStorage.setItem("lastTokenTime", time);
    //           }
    //           //}
    //           commit("SET_TOKEN", data.data);
    //           commit("SET_REFTOKEN", data.moreData.refreshToken);
    //         }
    //         resolve(response);
    //       })
    //       .catch((error) => {
    //         reject(error);
    //       });
    //   });
    // },
    // 注册
    Regist({ commit }, userInfo) {
        const username= userInfo.username.trim();
        const password = userInfo.password.trim();
        return new Promise((resolve, reject) => {
            system.regist(username, password).then(response => {
                const data = response.data;
                commit('SET_NAME', data.name);
                commit('SET_TOKEN', data.token);
                setName(data.name);
                setToken(data.token);
                resolve(response);
            }).catch(error => {
                reject(error) 
            }) 
        }) 
    },
    // 获取验证码
    SendVerification({ commit }, phone) {
      return new Promise((resolve, reject) => {
        system
          .sendVerification(phone)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 检验验证码是否正确，返回指定token
    CheckVerification({ commit }, verdata) {
      return new Promise((resolve, reject) => {
        system
          .checkVerification(verdata)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 保存重置的密码
    RetrievePwd({ commit }, data) {
      return new Promise((resolve, reject) => {
        system
          .retrievePwd(data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 登出
    LogOut({ dispatch, commit, state }) {
      let type = state.current.roleTypeCode
      dispatch("ClearLogOut");
      sessionStorage.setItem('roleTypeCode', type)
      // commit('SET_USERID', '')
      /* 清除权限后，自动重定向到登录页 */
      location.reload();
    },
    // 切换公司
    ChangeFirm({ dispatch, commit, state}, data){
        const id = data.firmId;
        const token = data.token;
        return new Promise((resolve, reject) => {
            system.changeFirm(id, token).then(response => {
                if(response.ok) {
                    //清除token等登录信息
                    dispatch('SwitchLogOut');
                    //把新的登录信息保存在vuex
                    commit('SET_NAME', data.name);
                    commit('SET_TOKEN', response.data);
                    commit('SET_REFTOKEN', response.moreData.refreshToken);
                    localStorage.setItem('lastTokenTime',new Date().getTime())
                }
                resolve(response);
            }).catch(error => {
                // 超时跳回登陆页面
                router.push("/login");
                reject(error)
            })
        })
    },
    // 切换
    SwitchLogOut({ commit, state }) {
      commit("SET_NAME", "");
      commit('SET_USERID', '')
      // commit("SET_TOKEN", "");
      // commit("SET_REFTOKEN", "");
      setName("");
      // setToken("");
      // setRefToken("");
      sessionStorage.clear();
      msg.disconnect();
      //同步到同一浏览器其他窗口的sessionStorage
      // localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
      // localStorage.removeItem('sessionStorage');
      if (window.$vueEl) {
        window.$vueEl.$notify.closeAll();
      }
    },
    // 退出
    ClearLogOut({ commit, state }) {
      commit("SET_NAME", "");
      commit('SET_USERID', '')
      // commit("SET_TOKEN", "");
      // commit("SET_REFTOKEN", "");
      setName("");
      // setToken("");
      // setRefToken("");
      const devHost = sessionStorage.__fool_devAppsConfig;
      sessionStorage.clear();
      devHost && sessionStorage.setItem("__fool_devAppsConfig", devHost);
      msg.disconnect();
      //同步到同一浏览器其他窗口的sessionStorage
      localStorage.setItem("sessionStorage", JSON.stringify(sessionStorage));
      localStorage.removeItem("sessionStorage");
      if (window.$vueEl) {
        window.$vueEl.$notify.closeAll();
      }
    },
    //获取在线数
    getOnlineTotal() {
      return new Promise((resolve, reject) => {
        system
          .getOnlineTotal()
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    //切换身份
  },
};
export default user;
