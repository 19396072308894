import { 
    fantBase, fantElement,
    tree, treebox, datebox, fantTable, search, setAuthList
} from "vue-fant-base-plus"
import ElementUI from 'element-plus'
import '@/styles/element-variables.scss'
import 'vue-fant-base-plus/index.scss';
import '@/styles/app.scss';
import Scrollbar from "smooth-scrollbar";

const fant = function(){}

fant.install = (app, {store})=>{
    /* 配置fant插件 */
    app.config.fant = {};
    // app.prototype.$bus = new Vue();
    /*
    * 记录模块打开窗口的数量，以便在切换模块的时候判断是否进行提示
    * */
    app.config.fant.beforeOpenWin = (vm) => {
        //解决firefox下，弹窗太慢时出现弹窗从左到右移动的问题
        vm.$el.style.visibility = "hidden";
        // if (self !== top) {
        //   let $parentEl = window.parent.$vueEl.$children[0];
        //   $parentEl.moduleWinCount++;
        //   //解决edge浏览器进入弹窗时可能错位的问题
        //   if ($parentEl.moduleWinCount > 0) {
        //     self.document.getElementsByTagName('body')[0].style.overflow = 'hidden';
        //   }
        // }
        //多窗口弹出的时候，其他后置窗口以定位方式隐藏
        if (vm.$el.closest(".el-dialog")) {
            if (
            !vm.$el.closest(".el-dialog").hasClass("is-fullscreen") &&
            !vm.$el.closest(".el-dialog").parentNode.hasClass("fant-win-visible")
            ) {
            vm.$el.closest(".el-dialog").parentNode.addClass("fant-win-visible");
            } else {
            let arr = vm.$fromArray(
                vm.$el.closest(".el-dialog").getElementsByClassName("fant-is-show")
            );
            if (arr.length > 0) {
                let targetWin = null;
                let compIndex = 0;
                arr.forEach((item) => {
                let zIndex = item.style.zIndex ? item.style.zIndex : 0;
                if (parseInt(zIndex) > compIndex && item !== vm.$el) {
                    compIndex = parseInt(zIndex);
                    targetWin = item;
                }
                });
                if (targetWin) {
                targetWin
                    .getElementsByClassName("el-dialog")[0]
                    .parentNode.addClass("fant-win-visible");
                }
            }
            }
        }
        // 解决弹窗内再弹窗时由于父窗口初始化了滚动条而导致子窗口样式异常的问题
        if (
            vm.$el.closest(".content-wrap") &&
            Scrollbar.has(vm.$el.closest(".content-wrap"))
        ) {
            Scrollbar.destroy(vm.$el.closest(".content-wrap"));
        }
    };
    app.config.fant.winOnClose = (vm) => {
        // //弹窗关闭后，删除当前tab页的链路导航的最后一级导航
        // let $cp = vm.$children[0].$children[0];
        // //直接引入包含弹窗组件win的组件特例，如taskView等组件
        // if (vm.$parent.$el.hasClass("el-dialog__wrapper")) {
        //   $cp = vm.$parent;
        // }
        // let name = store.state.currentTab;
        // if ($cp.$vnode.componentOptions.Ctor.options.meta && $cp.$vnode.componentOptions.Ctor.options.meta.name && name) {
        //   let arr = store.state.navPath[name];
        //   arr.splice(arr.length - 1, 1);
        //   store.commit('setNavPath', { name: name, value: arr });
        //   $cp.nav__ = [$cp.$vnode.componentOptions.Ctor.options.meta.name];
        // }
        //多窗口弹出后关闭，显示原来的父窗口
        if (vm.$refs.elWin.dialogContentRef.$el.closest(".el-dialog")) {
            let arr = vm.$fromArray(
            vm.$refs.elWin.dialogContentRef.$el.closest(".el-dialog").getElementsByClassName("fant-is-show")
            );
            if (arr.length > 0) {
            let targetWin = null;
            let compIndex = 0;
            arr.forEach((item) => {
                let zIndex = item.style.zIndex ? item.style.zIndex : 0;
                if (parseInt(zIndex) > compIndex) {
                compIndex = parseInt(zIndex);
                targetWin = item;
                }
            });
            if (targetWin) {
                targetWin
                .getElementsByClassName("el-dialog")[0]
                .parentNode.removeClass("fant-win-visible");
            }
            } else {
            vm.$refs.elWin.dialogContentRef.$el.closest(".el-dialog").parentNode.removeClass("fant-win-visible");
            }
        }
    };
    app.config.fant.beforeCloseWin = (vm) => {
        // if (self !== top) {
        //   let $parentEl = window.parent.$vueEl.$children[0];
        //   $parentEl.moduleWinCount--;
        //   //解决edge浏览器进入弹窗时可能错位的问题
        //   if ($parentEl.moduleWinCount == 0) {
        //     self.document.getElementsByTagName('body')[0].style.overflow = 'auto';
        //   }
        // }
        setTimeout(() => {
            // 解决弹窗内再弹窗时由于父窗口初始化了滚动条而导致子窗口样式异常的问题
            if (vm.$refs.elWin.dialogContentRef.$el.closest(".content-wrap")) {
            let arr = vm.$refs.elWin.dialogContentRef.$el
                .closest(".content-wrap")
                .getElementsByClassName("fant-is-show");
            if (arr.length == 0 && !Scrollbar.has(vm.$refs.elWin.dialogContentRef.$el.closest(".content-wrap"))) {
                Scrollbar.init(vm.$refs.elWin.dialogContentRef.$el.closest(".content-wrap"), {
                alwaysShowTracks: true,
                });
            }
            }
        }, 300);
        //移除事件
        // if (self != top && vm.$$tabEvent) {
        //   self.$vueEl.$el.removeEventListener('keydown', vm.$$tabEvent);
        // }
    };
    app.config.fant.winOnRender = (vm) => {
        // //根据组件的meta.name属性记录当前tab页的链路导航
        // let $cp = vm.$children[0].$children[0];
        // //直接引入包含弹窗组件win的组件特例，如taskView等组件
        // if (vm.$parent.$el.hasClass("el-dialog__wrapper")) {
        //   $cp = vm.$parent;
        // }
        // let name = store.state.currentTab;
        // if ($cp.$vnode.componentOptions.Ctor.options.meta && $cp.$vnode.componentOptions.Ctor.options.meta.name && name) {
        //   let arr = store.state.navPath[name].concat($cp.nav__);
        //   store.commit('setNavPath', { name: name, value: arr });
        //   $cp.nav__ = arr;
        // }
        vm.$nextTick(() => {
            //初始化弹窗的滚动条
            if (
            vm.$refs.elWin.dialogContentRef.$el.getElementsByClassName("content-wrap")[0] &&
            !Scrollbar.has(vm.$refs.elWin.dialogContentRef.$el.getElementsByClassName("content-wrap")[0])
            ) {
            Scrollbar.init(vm.$refs.elWin.dialogContentRef.$el.getElementsByClassName("content-wrap")[0], {
                alwaysShowTracks: true,
            });
            }
            //解决弹窗tab键切换input时会切换到父页面input的问题
            // if (self != top) {
            //   self.$vueEl.$el.addEventListener('keydown', vm.$$tabEvent = (e) => {
            //     if (e.keyCode == 9) {
            //       if (!vm.$isParent(e.target, vm.$el) || vm.$el.hasClass('fant-win-visible')) {
            //         if (e.preventDefault) {
            //           e.preventDefault(); //非IE阻止默认事件
            //         } else {
            //           e.returnValue = false; //IE阻止默认事件
            //         }
            //       }
            //     }
            //   });
            // }
        });
    };
    app.config.fant.tableOnRender = (vm) => {
        //初始化表格的滚动条
        if (
            vm.$el.getElementsByClassName("el-table__body-wrapper")[0] &&
            !Scrollbar.has(vm.$el.getElementsByClassName("el-table__body-wrapper")[0])
        ) {
            vm.$scrollbar = Scrollbar.init(
            vm.$el.getElementsByClassName("el-table__body-wrapper")[0],
            { alwaysShowTracks: true }
            );
        }
        };
        app.config.fant.tableOnUpdated = (vm) => {
        //滚动条的更新
        if (vm.$scrollbar) {
            vm.$scrollbar.update();
        }
    };
    /* 把权限数据加载到fant插件里面 */
    if (sessionStorage.getItem("auth")) {
        const authList = JSON.parse(window.sessionStorage.getItem("auth"));
        setAuthList(authList)
    }
    
    /* 注册常用的fant组件 */
    app.use(tree)
    app.use(fantTable)
    app.use(treebox)
    app.use(datebox)
    app.use(search)
    app.use(ElementUI)
    /* 完成配置后，初始化vue-fant-base-plus */
    app.use(fantBase,{
        //v-auth，$hasAuth权限过滤函数
        authFilter:(value)=>{
            // const info = store.state.appStore.route
            // value = `${info.system}.${info.name}.${value}`
            const systemNameArr = value.split(".")
            value = `${systemNameArr[0]}.${value}`
            return value
        }
    });
    /* ElementUI传入，作按需引入 */
    app.use(fantElement);
}

export default fant