const serverApi = window.location.hostname
/**
 * 开发环境下的路由类型微应用注册，比如系统管理模块等
 * 生产环境下是根据后台接口动态加载注册
 * 无特殊情况，路由类型微应用渲染的container都是layout页面的#subapp-view
 * @Author: tanyc 
 * @Date: 2021-03-11 10:39:16 
 */
const apps = {
    sysman:{
        id: 'subapp-view-sysman',
        name: 'sysman',
        // entry: 'http://192.168.0.26:8301',
        entry: `http://${serverApi}:8601`,
        // entry: 'http://192.168.0.203:8015/sysman/',
        container: '#subapp-view-sysman',
        activeRule: '/sysman',
    },
    'affairs':{
        id: 'subapp-view-affairs',
        name: 'affairs',
        // entry: 'http://192.168.0.203:8015/basedata',
        entry: `http://${serverApi}:8602`,
        container: '#subapp-view-affairs',
        activeRule: '/affairs'
    },
    'archives':{
        id: 'subapp-view-archives',
        name: 'archives',
        // entry: 'http://192.168.0.203:8015/basedata',
        entry: `http://${serverApi}:8603`,
        container: '#subapp-view-archives',
        activeRule: '/archives'
    },
    'oa':{
        id: 'subapp-view-oa',
        name: 'oa',
        // entry: 'http://192.168.0.203:8015/basedata',
        entry: `http://${serverApi}:8604`,
        container: '#subapp-view-oa',
        activeRule: '/oa'
    },
    'partywork':{
        id: 'subapp-view-partywork',
        name: 'partywork',
        entry: `http://${serverApi}:8605`,
        container: '#subapp-view-partywork',
        activeRule: '/partywork'
    },
} as Record<string, any>

const microAppConfig = []

for(let i in apps){
    microAppConfig.push(apps[i])
}

export default apps;

export {
    microAppConfig
}