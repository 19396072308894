const myRoutesFiles = import.meta.globEager('./module/*.js')
const myRoutes = Object.keys(myRoutesFiles).reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const value = myRoutesFiles[modulePath]
  modules = modules.concat(value.default)
  return modules
}, [])
import { createRouter, createWebHistory } from 'vue-router'
function mycreateRouter () {
    return createRouter({
        history: createWebHistory(import.meta.env.BASE_URL),
        routes: [
            {
                path: "/",
                name: "layout",
                
                component: () => import('@/views/layout/layout.vue'),
                children: [
                    // {
                    //     path: 'sysman/:pathMatch(.*)',
                    //     name: 'sysman',
                    //     component: async () => {
                    //         const cmp:any = (await import('@/views/layout/subapp.vue')).default
                    //         return {...cmp, name:'sysman'}
                    //     },
                    //     props:true
                    // },
                    /**
                     * @meta title 显示在上方tab标签栏的名称
                     * @meta resident 是否常驻在tab标签栏（不可关闭）
                     * @meta icon tab标签栏的图标class
                     * @meta exclude tab标签栏不会根据url匹配该路由从而新建tab
                     */
                    /* 暂时屏蔽自定义首页 */
                    // { //首页
                    //     path: '',
                    //     name: 'index',
                    //     // redirect:'/sysman/sysman.userStaff',
                    //     children:[],
                    //     meta:{
                    //         title:'我的首页',
                    //         resident:true,
                    //         icon:'el-icon-fant1-xuexiao2'
                    //     },
                    //     component:  () => import('@/views/platform/index.vue'),
                    // },
                    // { //空白页，进系统后显示页
                    //     path: '',
                    //     name: 'empty',
                    //     children:[],
                    //     component:  () => import('@/views/platform/empty.vue'),
                    // },
                    { //消息
                        path: '/messageCenter',
                        name: 'messageCenter',
                        meta:{
                            title:'我的消息'
                        },
                        component:  () => import('@/views/message/message.vue'),
                    },
                    { //用户设置
                      path: '/userSetting',
                      name: 'userSetting',
                      meta:{
                        title:'用户配置'
                    },
                      component:  () => import('@/views/platform/userSetting/index.vue'),
                    },
                    {
                        path: 'instatus/:code',
                        name: 'instatus',
                        meta:{
                            exclude:true
                        },
                        component: () => import('@/views/layout/status.vue'),
                        props: true
                    }
                ],
            },
            {
                path: '/frame',
                name: 'frame',
                component: () => import('@/views/frame/frame.vue')
            },
            {
                path: '/login',
                name: 'login',
                component: () => import('@/views/layout/login.vue')
            },
            {
                path: '/adminLogin',
                name: 'adminLogin',
                component: () => import('@/views/layout/backLogin.vue')
            },
            {
                path: '/transit',
                name: 'transit',
                component: () => import('@/views/layout/transit.vue')
            },
            // {
            //     path:'/',
            //     redirect: '/transit'
            // },
            {
                path: '/register',
                name: 'register',
                component: () => import('@/views/layout/register.vue')
            },
            // {
            //     path: '/retrievePassword',
            //     name: 'retrievePassword',
            //     component: resolve => require(['../views/layout/retrievePassword.vue'], resolve)
            // },
            {
                path: '/status/:code',
                name: 'status',
                component: () => import('@/views/layout/status.vue'),
                props: true
            },
						{
						    path: '/staffBind',
						    name: 'staffBind',
						    component: () => import('@/views/layout/staffBind.vue')
						},
						{
						    path: '/agreement',
						    name: 'agreement',
						    component: () => import('@/views/layout/agreement.vue')
						},
            // { path: '*', component: () => import('@/views/layout/subapp.vue') }
        ]
    });
}

let router = mycreateRouter();

export default router