import {
    loadMicroApp
} from 'qiankun';
import router from '@/router/index'
import { useFts } from 'fant-microsass-web';
import devApps from '@/boot/devApps'
import store from '@/store';
import actions from '@/boot/qiankun/actions.js';
import request from '@/utils/request'

import {
    ElMessageBox
} from 'element-plus'

function setTabsList(tabsList){
    sessionStorage.setItem('tabsList', JSON.stringify(tabsList))
}
export function getTabsList(){
    return JSON.parse(sessionStorage.getItem('tabsList') || '[]')
}


// tab最多个数
const pageTabMax = 30;
let once = false
export default {
    namespaced: true,
    state: {
        appLoading: false, // 加载状态
        installAppMap: {}, // 已安装的微应用
        activeTab: {}, // 当前活跃tab索引
        tabsList: [], // 当前存在的tab页
        keepAliveList: {}, // 需要保存状态的页面
        componentOpenedSubapps:[] // 打开了公共组件的子应用
    },
    actions: {
        pushInstallMricoAppMap({
            commit
        }, data) {
            commit('PUSH_INSTALL_MRICOAPP_MAP', data)
        },
        pushTabsList({
            state,
            getters,
            commit
        }, data) {
            const find = state.tabsList.find((item) => item.path === data.path)
            // 如果tab列表没有超出就直接push
            if (getters.tabsList.length < pageTabMax || find) {
                commit('PUSH_TABS_LIST', data)
                return false
            } else {
                if(once){
                    return true;
                }
                once = true
                ElMessageBox.confirm(
                    '系统只能同时存在' + pageTabMax + '标签页，请删除掉不重要的标签页再操作!',
                    '提示', {
                        showCancelButton: false,
                        confirmButtonText: '确定',
                        type: 'warning'
                    }
                ).then(() => {
                    once = false
                    // router.push({path:lastRoute})
                }).catch(()=>{
                    once = false
                    // router.push({path:lastRoute})
                })
                return true
                /* eslint-disable */
                // if (window.alert('系统能同时存在最多' + pageTabMax + '个路由Tabs标签页,超出限制时将关闭第一个Tabs标签页!确定跳转并关闭首个Tabs标签页吗?')) {
                //     // 否者超出了就删除第一个tab页  再push
                //     let tabList = [...getters.tabsList]
                //     let removeItem = tabList[0]
                //     tabList.shift()

                //     const appName = removeItem.appName
                //     // 如果是主应用
                //     if (appName === 'master') {
                //         getters.keepAliveList['master'] = getters.keepAliveList['master'].filter((item) => item !== removeItem.name)
                //     } else {
                //         // 否者是微应用
                //         let installApp = {
                //             ...getters.installAppMap
                //         }
                //         // 如果微应用没有活跃的tab了就销毁 并且 跳转的不是当前微应用的页面
                //         if (!tabList.some((item) => item.appName === appName) && appName !== data.appName) {
                //             console.warn('🚀🚀🚀微页面[' + appName + ']已经销毁了!!!')
                //             installApp[appName].unmount()
                //             delete installApp[appName]
                //             commit('PUSH_INSTALL_MRICOAPP_MAP', installApp)
                //         } else {
                //             installApp[appName] && installApp[appName].update({
                //                 routerEvent: {
                //                     path: removeItem.path,
                //                     type: 'close'
                //                 }
                //             })
                //         }
                //     }

                //     commit('SET_TABS_LIST', tabList)
                //     commit('PUSH_TABS_LIST', data)
                // }
            }
        },
        closeSubappComponent({ commit, state, getters }, subappName){
            let subapps = [...state.componentOpenedSubapps]
            subapps.splice(subapps.indexOf(subappName), 1)
            commit('SET_COMPONENT_OPENED_SUBAPPS', subapps)
        },
        openSubappComponent({ commit, state, getters }, { subappName, publicKey, publicParams, onloaded }){
            if(getters.installAppMap[subappName]){
                const app = getters.installAppMap[subappName]
                if (app && app.update) {
                    app.update({
                        publicComponent:{
                            publicKey,
                            publicParams
                        }
                    })
                    onloaded instanceof Function?onloaded():null
                }
            }else{
                const appConfig = devApps[subappName]
                if(appConfig){
                    const app = loadMicroApp({
                        ...appConfig,
                        props: {
                            $parentRouter: router,
                            actions: actions,
                            publicKey,
                            publicParams,
                            request,
                            store
                        }
                    }, null, {
                        afterMount:()=>{
                            onloaded instanceof Function?onloaded():null
                        }
                    })
                    const installApp = {
                        ...getters.installAppMap
                    }
                    installApp[subappName] = app
                    commit('PUSH_INSTALL_MRICOAPP_MAP', installApp)
                }
            }
            let subapps = [...state.componentOpenedSubapps]
            subapps.push(subappName)
            commit('SET_COMPONENT_OPENED_SUBAPPS', subapps)
        },
        async closeTabsList({
            getters,
            commit
        }, data) {
            // 只有一条的时候删除
            if (getters.tabsList.length === 1) {
                commit('SET_TABS_LIST', [])
                commit('CHANGE_ACTIVE_TAB', {})

                try {
                    // 销毁所有微应用
                    for (let name in getters.installAppMap) {
                        console.warn('🚀🚀🚀微页面[' + name + ']已经销毁了!!!')
                        let app = getters.installAppMap[name]
                        if (app && app.getStatus() == 'MOUNTED') {
                            app.unmount()
                        }
                    }
                } catch (error) {
                    console.log(error)
                }

                commit('PUSH_INSTALL_MRICOAPP_MAP', {})

                //跳转首页
                router.replace('/')
                return
            }
            let nowActiveTab = JSON.parse(JSON.stringify(getters.activeTab))
            let tabList = [...getters.tabsList]
            let frist = tabList[0]
            let last = tabList[tabList.length - 1]

            let activeTab = null;
            if (nowActiveTab.path === data.path) {
                // 如果删除的是当前活跃的tab
                tabList = tabList.filter((item, index) => {
                    if (nowActiveTab.path !== item.path) {
                        return item
                    }
                })
                activeTab = tabList[tabList.length - 1]
            } else if (data.path === last['path']) {
                // 先对比尾部
                tabList.pop()
                activeTab = tabList[tabList.length - 1]
            } else if (data.path === frist['path']) {
                // 再对比头部
                tabList.shift()
                activeTab = nowActiveTab
            } else {
                // 都匹配不上就对比中间
                let findIndex = -1
                tabList = tabList.filter((item, index) => {
                    if (item.path !== data.path) {
                        return item
                    } else {
                        findIndex = index > 1 ? index : 1
                    }
                })
                activeTab = nowActiveTab
            }

            const appName = data.appName
            // 如果是主应用
            if (appName === 'master') {
                commit('SET_KEEPALIVE_LIST',{ appName:'master', data:getters.keepAliveList['master']?getters.keepAliveList['master'].filter((item) => item !== data.name):[]})
                // getters.keepAliveList['master'] = getters.keepAliveList['master'].filter((item) => item !== data.name)
            } else {
                // 否者是微应用
                let installApp = {
                    ...getters.installAppMap
                }

                // 如果微应用没有活跃的tab了就销毁
                if (!tabList.some((item) => item.appName === appName)) {
                    try {
                        // console.warn('🚀🚀🚀微页面[' + appName + ']已经销毁了!!!')
                        // if (installApp[appName].getStatus() == 'MOUNTED') {
                        //     installApp[appName].unmount()
                        // }
                        // delete installApp[appName]
                        commit('PUSH_INSTALL_MRICOAPP_MAP', installApp)
                    } catch (error) {
                        console.log(error)
                    }
                } else if(installApp[appName] && installApp[appName].update) {
                    installApp[appName].update({
                        routerEvent: {
                            path: data.path,
                            type: 'close'
                        }
                    })
                }
            }

            commit('SET_TABS_LIST', tabList)
            // const installApp = getters.installAppMap
            if (activeTab.path !== nowActiveTab.path) {
                const fts = useFts()
                const code = activeTab.params.pathMatch
                if(code){
                    fts.systemReplace(code, {}, activeTab.appName)
                    // installApp[activeTab.appName] && installApp[activeTab.appName].update({
                    //     routerEvent: {
                    //         ...activeTab,
                    //         type: 'replace' // 如果存在就是切换路由  否者就是添加
                    //     }
                    // })
                }else{
                    fts.systemReplace(activeTab.name, {}, 'master')
                    commit('CHANGE_ACTIVE_TAB', activeTab)
                }
            }
        },
        changeTabsList({
            commit
        }, data) {
            commit('CHANGE_TABS_LIST', data)
        },
        changeActiveTab({
            commit
        }, data) {
            commit('CHANGE_ACTIVE_TAB', data)
        },
        pushKeepAliveList({
            commit
        }, data) {
            commit('PUSH_KEEPALIVE_LIST', data)
        },
        changeAppLoading({
            state,
            commit
        }, data) {
            if (state.appLoading === data) {
                return
            }
            commit('CHANGE_APP_LOADING', data)
        }
    },
    mutations: {
        // 添加加载了公共组件的子应用名称
        ['SET_COMPONENT_OPENED_SUBAPPS'](state, data) {
            state.componentOpenedSubapps = data
        },
        // 添加挂载微应用
        ['PUSH_INSTALL_MRICOAPP_MAP'](state, data) {
            state.installAppMap = data
        },
        // 添加活跃的tabs
        ['PUSH_TABS_LIST'](state, data) {
            if(!state.tabsList.find((item)=>item.path == data.path)){
                state.tabsList.push(data)
            }
            setTabsList(state.tabsList)
            state.activeTab = data
        },
        // 删除活跃的tabs
        ['SET_TABS_LIST'](state, data) {
            state.tabsList = data
            setTabsList(state.tabsList)
        },
        // 根据索引删除活跃的tabs
        ['CLOSE_TABS_BYINDEX'](state, index) {
           state.tabsList.splice(index,1)
           setTabsList(state.tabsList)
        },
        // 改变活跃的activeTab
        ['CHANGE_ACTIVE_TAB'](state, data) {
            state.activeTab = data
        },
        // 改变活跃的activeTab
        ['PUSH_KEEPALIVE_LIST'](state, data) {
            if (!state.keepAliveList[data.appName]) {
                state.keepAliveList[data.appName] = [data.name]
            } else {
                state.keepAliveList[data.appName].push(data.name)
            }
        },
        ['SET_KEEPALIVE_LIST'](state, { appName, data }) {
                state.keepAliveList[appName] = data
        },
        // 添加挂载微应用
        ['CHANGE_APP_LOADING'](state, data) {
            state.appLoading = data
        }
    },
    getters: {
        installAppMap: (state) => state.installAppMap, // 已经加载过的App
        tabsList: (state) => state.tabsList, // 已存在的tab列表
        activeTab: (state) => state.activeTab, // 活跃的tab
        keepAliveList: (state) => state.keepAliveList, // 活跃的tab
        appLoading: (state) => state.appLoading // loading  
    }
}