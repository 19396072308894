export default {
  shuzhi:{
    name:'数智社区',
    title: '数智社区管理平台',
    aTitle: '数智社区居民委员会',
    longitude: '113.149448',
    latitude: '23.035010',
    address: '广东省佛山市南海区',
    loginLogo: '@/assets/images/login/login_logo.png',
    loginImg: '@/assets/images/login/login_img.png',
    topLogo: '@/assets/images/login/top_logo.png',
    favicon: '/favicon2.ico',
    isRegisterMode:true,//是否需要注册用户
  },
  pingdi: {
    name:'平地数智社区',
    title: '平地数智社区管理平台',
    aTitle: '平地社区居民委员会',
    longitude: '113.163916',
    latitude: '23.119164',
    address: '广东省佛山市南海区平安路3号',
    loginLogo: '@/assets/images/login/pingdi/login_logo.png',
    loginImg: '@/assets/images/login/pingdi/login_img.png',
    topLogo: '@/assets/images/login/pingdi/top_logo.png',
    favicon: '/favicon.ico',
    isRegisterMode:true,//是否需要注册用户
  },
  shiken: {
    name:'石肯数智社区',
    title: '石肯数智社区管理平台',
    aTitle: '石肯社区居民委员会',
    longitude: '113.16278',
    latitude: '23.014819',
    address: '广东省佛山市南海区教育路1号石肯社区',
    loginLogo: '@/assets/images/login/shiken/login_logo.png',
    loginImg: '@/assets/images/login/login_img.png',//公用图片
    topLogo: '@/assets/images/login/shiken/top_logo.png',
    favicon: '/favicon2.ico',
    isRegisterMode:false,//是否需要注册用户
    defaultPassword:'shiken123',//(不需要注册用户的默认密码)一定要结合isRegisterMode:false配置
  },
  linyue: {
    name:'林岳数智社区',
    title: '林岳数智社区管理平台',
    aTitle: '林岳社区居民委员会',
    longitude: '113.240985',
    latitude: '23.000710',
    address: '广东省佛山市南海区林岳大道北100米林岳社区',
    loginLogo: '@/assets/images/login/linyue/login_logo.png',
    loginImg: '@/assets/images/login/login_img.png',//公用图片
    topLogo: '@/assets/images/login/linyue/top_logo.png',
    favicon: '/favicon2.ico',
    isRegisterMode:true,//是否需要注册用户
    defaultPassword:'linyue123',//(不需要注册用户的默认密码)一定要结合isRegisterMode:false配置
  }
}