import { BASE_URL, BASEMSG_URL } from '@/utils/logRequest'
import { getCurrent } from '@/utils/tools'
import { request } from '@/utils/request'
import store from '@/store'
import router from '@/router'
import { Router } from 'vue-router'
// import { registerApps } from '@/apps'
import devApps from './devApps'
import { bus, setAuthList } from 'vue-fant-base-plus'
const prod = import.meta.env.VITE_NODE_ENV === 'production'
/* 准生产环境，方便调试生产问题，并且可作为前端子项目开发环境的外框使用 */
export const staging = import.meta.env.MODE === 'staging'
console.log(import.meta.env.NODE_ENV,import.meta.env.VITE_NODE_ENV)
/**
 * 根据菜单加载路由，并且返回微应用列表
 * @param {Router} router 
 * @param {array} services 
 * @returns 
 */
export const loadRoutesAndGetApps = (myrouter:Router)=>{
    const apps = []
    const apps_ = []
    /* 作为前端子项目开发环境的外框使用时，提供sessionStorage的__fool_devAppsConfig配置子项目的加载地址 */
    let devAppsConfig = {} as Record<string, any>
    if(staging && sessionStorage.__fool_devAppsConfig){
        try{
            devAppsConfig = JSON.parse(sessionStorage.__fool_devAppsConfig)
        }catch(err){
            devAppsConfig = {}
        }
    }
    (window as any).$subAppsConfig = (window as any).$subAppsConfig || {}
    for(let i in devApps){
        const item = devApps[i]
        // let domain = typeof item.domain == 'string' && item.domain[item.domain.length-1]!=='/'?(item.domain+'/'):item.domain
        const myapp = (prod && (window as any).$subAppsConfig[i]?(window as any).$subAppsConfig[i]:item)||item
        let baseurl = import.meta.env.BASE_URL.replace(/\//g, '')
        baseurl = baseurl?('/'+baseurl):baseurl
        let entry = staging && devAppsConfig[i]?devAppsConfig[i]:prod?(location.origin+baseurl+'/'+item.name+'/'):myapp.entry;
        entry = entry + (entry.indexOf('?')!=-1?'&r=':'?r=') + Math.random()
        myapp.entry = entry
        if(myapp){
            staging&&!devAppsConfig[myapp.name]?(devAppsConfig[myapp.name] = myapp.entry):null
            apps_.push(myapp)
            apps.push({
                ...myapp,
                props: {
                    /* 每个微应用的菜单，此处不能传实例，因为刷新后经过JSON转换处理后实例会掉失 */
                    menus: item.children
                }
            })
            myrouter.addRoute('layout',{
                path: myapp.activeRule+'/:pathMatch(.*)',
                name: item.name,
                // component:async () => {
                //     const cmp:any = (await import('@/views/layout/subappView.vue')).default
                //     return {...cmp, name:item.moduleCode}
                // },
                component:()=>import('@/views/layout/subappView.vue'),
                props:true
                // children:[
                //     {
                //         path:'*',
                //         component: () => import('@/views/layout/subapp.vue'),
                //     }
                // ]
            })
        }
        if (staging && devAppsConfig) {
          sessionStorage.setItem('__fool_devAppsConfig', JSON.stringify(devAppsConfig))
        }
    }
    store.commit('appStore/SET_APPINFO', apps_)
    sessionStorage.setItem('__fool_microApps', JSON.stringify(apps))
    return apps
}


export default (app:any)=>{
    return new Promise(async(resolve:Function)=>{
        /**
         * 默认跳转的微应用，通常是微应用列表的首个应用
         */
        // let defaultApp = ''
        /**
         * 记录每个页面对应的展示模块code
         */
        let showModuleCodes = {} as Record<string, any>;
        /**
         * 登陆后刷新直接获取本地记录的菜单和权限数据
         */
        // if (sessionStorage.getItem("token")) {
        //     /* 记录权限 */
        //     store.commit('SET_NAME', window.sessionStorage.getItem("name"));
        //     store.commit('SET_TOKEN', window.sessionStorage.getItem("token"));
        //     store.commit('SET_REFTOKEN', window.sessionStorage.getItem("refreshToken"));
        // }
        if ((store.state as any).user.userId && !sessionStorage.getItem("auth")) {
            /* 如果成功登陆后，加载权限和菜单 */
            const perRes:any = await request.get("/web/common/sysman/user/permission")
            if (perRes.ok) {
                // services = perRes.data
                let authArr = [] as any[];
                perRes.data.forEach((item:Record<string, any>) => {
                    const moduleCode = item.moduleCode;
                    const authCode = moduleCode + "." + item.resourceCode + "." + item.code;
                    authArr.push(authCode);
                });
                let permissions = JSON.stringify(authArr);
                sessionStorage.setItem("auth", permissions);
                /* 把权限数据加载到fant插件里面 */
                // (app.config as any).fant.authList = authArr;
                setAuthList(authArr)
            }
            //获取企业所有人员数据，并存入sessionStorage
            // const basedataRes:any = await request.get("/web/staff/firm/list/basedata")
            // if (basedataRes) {
            //     let employees:Record<string, any> = {};
            //     basedataRes.forEach((item:Record<string, any>) => {
            //         employees[item.id] = item;
            //     });
            //     sessionStorage.setItem("employees", JSON.stringify(employees));
            // }
            if ((store.state as any).user.loginInfo.userType !== 'ADMIN') {
                const identity = await request.get('/web/open/sysman/member/bind/currentInfo');
                if(identity.ok && identity.data?.length){
                    store.commit('SET_IDENTITYLIST',identity.data)
                    let roleTypeCode = 'STAFF',
                    roleTypeBizId = identity.data.find((item:any) => {
                        return item.roleTypeCode === 'STAFF'
                    })?.roleTypeBizId || ''
                    if (identity.data.length === 1 && identity.data[0].roleTypeCode === 'PARTYMEMBER') {
                        roleTypeCode = 'PARTYMEMBER'
                        roleTypeBizId = identity.data[0].roleTypeBizId
                    }
                    const query = `?buzDataId=${roleTypeBizId}&userRoleType=${roleTypeCode}`
                    request.put(`/web/common/sysman/user/changeUserType${query}`);
                }
                if(!sessionStorage.getItem("current")){
                    await getCurrent()
                }
            }
        }
        if(!sessionStorage.getItem("menu")){
            // const firmRes:any = await request.get("/web/firm")
            // if (firmRes.ok){
            //     sessionStorage.setItem("firm", JSON.stringify(firmRes.data));
            //     //不是学校运营员
            //     // if(!firmRes.data.operator){
            //     //     const firmBase:any = await request.get("/web/operating/school/info/"+firmRes.data.id)
            //     //     if (firmBase.ok){
            //     //         const firmBaseData = firmBase.data
            //     //         sessionStorage.setItem("firmBaseData", JSON.stringify(firmBaseData));
            //     //     }
            //     // }
            // }
            // if (firmRes.ok && firmRes.data.operator) {
            //     app.config.globalProperties.$operator = true
            //     sessionStorage.setItem("operator", app.config.globalProperties.$operator);
            // }else{
            //     app.config.globalProperties.$operator = false
            //     sessionStorage.setItem("operator", app.config.globalProperties.$operator);
            // }
            // const current = JSON.parse(sessionStorage.getItem('current') || '')
            const menuRes:any = await request.get(`/web/common/sysman/user/menu`)
            if(menuRes.ok){
                let menus = menuRes.data;
                // menus[0]={
                //     children:[],
                //     name: "系统管理",
                // }
                // menus[0].children.push({
                //     children: [],
                //     code: "web.role",
                //     domain: "/sysman/",
                //     icon: "",
                //     id: "1bb7a715-7a7e-48db-a94f-ba2a2f3285a9",
                //     moduleCode: "sysman",
                //     moduleVersion: 2,
                //     name: "角色",
                //     ordered: 3,
                //     parentId: "32367d4b-c2cb-11e8-80f6-509a4c63dd82",
                //     type: "resource",
                // })
                // menus[0].children.push({
                //     children: [],
                //     code: "web.user",
                //     domain: "/sysman/",
                //     icon: "",
                //     id: "1bb7a715-7a7e-48db-a94f-ba2a2f3285a9",
                //     moduleCode: "sysman",
                //     moduleVersion: 2,
                //     name: "用户",
                //     ordered: 3,
                //     parentId: "32367d4b-c2cb-11e8-80f6-509a4c63dd82",
                //     type: "resource",
                // })
                sessionStorage.setItem("menu", JSON.stringify(menus));
                const subapps:Record<string, any> = {} 
                const menusByCode:Record<string, any> = {}
                const menusByMoudleCode:Record<string, any> = {}
                const getMenusJson = (menus:any[], rootParentId?:string)=>{
                    menus.forEach(item=>{
                        if(item.moduleCode && (!item.children || item.children.length == 0)){
                            menusByMoudleCode[item.moduleCode] = menusByMoudleCode[item.moduleCode] || {}
                            menusByMoudleCode[item.moduleCode][item.code] = item
                            subapps[item.moduleCode] = subapps[item.moduleCode] || {moduleCode:item.moduleCode, children:[]}
                            subapps[item.moduleCode].children.push(item)
                            menusByCode[item.code] = item
                            menusByCode[item.code].rootParentId = rootParentId
                        }
                        if(item.children && item.children.length>0){
                            getMenusJson(item.children, rootParentId || item.id)
                        }
                    })
                }
                getMenusJson(menus)
                /* 子应用的资源集合，以便注册子应用 */
                sessionStorage.setItem("__fool_subapps", JSON.stringify(subapps));
                /* 通过code索引到对应的路由信息，包括属于哪个微应用这些信息 */
                sessionStorage.setItem("__fool_menusbycode", JSON.stringify(menusByCode));
                /* 子应用通过code索引到对应的路由信息 */
                sessionStorage.setItem("__fool_menusbymoudlecode", JSON.stringify(menusByMoudleCode));
                /* 无特殊情况，路由类型微应用渲染的container都是layout页面的#subapp-view */
                loadRoutesAndGetApps(router)

                /* 根据菜单接口动态注册微应用 */
                // registerApps(apps)
                
                // defaultApp = menus[0]?.children[0]?.children[0]?(menus[0]?.children[0]?.children[0]?.domain+menus[0]?.children[0]?.children[0]?.code):(menus[0]?.children[0]?.domain+menus[0]?.children[0]?.code)
                // const route = menus[0]?.children[0]?.children[0]?menus[0]?.children[0]?.children[0]:menus[0]?.children[0]
                // defaultApp = route
            }
        }
        /**
         * 加载路由
         */
        if (sessionStorage.getItem("menu")) {
            const menu = JSON.parse(sessionStorage.getItem("menu") || '[]')||[];
            const version = JSON.parse(sessionStorage.getItem("version") || '{}')||{};
            /* 仓库记录版本信息 */
            store.commit('setVersion', version)
            // store.dispatch("addMenu", menu);
            store.dispatch("setMenu", menu);
        }

        
        loadConfig(app)
        resolve()
    })
}

export const loadConfig = (app:any)=>{
    /* 主模块服务前缀设置在request里面 */
    app.config.globalProperties.$BASE_URL = BASE_URL
    /* 系统管理模块服务前缀 */
    // app.config.globalProperties.$SYSMAN_URL = import.meta.env.VITE_SYSMAN_URL
    /* oa模块服务前缀 */
    // app.config.globalProperties.$OA_URL = import.meta.env.VITE_OA_URL
    /* 基础模块服务前缀 */
    // app.config.globalProperties.$BASEDATA_URL = import.meta.env.VITE_BASEDATA_URL
    
    /**
     * 前缀配置
     */
     if (sessionStorage.getItem("menu")) {
        const version = JSON.parse(sessionStorage.getItem("version") || '{}') || {};
        /* 消息api前缀 */
        app.config.globalProperties.$FULL_MESSAGE_URL = BASEMSG_URL + import.meta.env.VITE_MESSAGE_URL + (prod?('/v' + version['message']):'')
        app.config.globalProperties.$MESSAGE_URL = import.meta.env.VITE_MESSAGE_URL;
        (bus as any).$FULL_MESSAGE_URL = app.config.globalProperties.$FULL_MESSAGE_URL
        /* 文件模块服务前缀 */
        // app.config.globalProperties.$FILE_URL = import.meta.env.VITE_FILE_URL
        /* 文件模块服务全前缀 */
        // app.config.globalProperties.$FULL_FILE_URL = BASE_URL + import.meta.env.VITE_FILE_URL + (prod?('/v' + version['file']):'')
    }

    /* 是否运营人员 */
    // if(sessionStorage.getItem("operator")){
    //     app.config.globalProperties.$operator = sessionStorage.getItem("operator") == 'true'?true:false
    // }

    // if(sessionStorage.getItem('token')){
    //     /* 必须在loadConfig之后 */
    //     store.dispatch('Subscribe').then(()=>{
    //         store.dispatch('OnMessage');
    //     })
    // }
}