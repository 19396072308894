<style scoped>
.page-box {
  overflow: inherit;
}
.main-page {
  min-width: 1280px;
  min-height: 680px;
}
</style>
<template>
  <div class="page-box full-width full-height main-page">
    <el-config-provider :locale="locale" v-cloak>
      <router-view ref="inRouter"></router-view>
    </el-config-provider>
  </div>
</template>
<script>
import msgAction from "@/components/msgAction.vue";
import { bus } from "vue-fant-base-plus";
import zhCn from "element-plus/es/locale/lang/zh-cn";
import sysmanConfig from '@/libs/config.js'
export default defineComponent({
  computed() {
    return {
      locale: zhCn,
    };
  },
  updated() {
    //修复切换tab页的时候，下拉框不消失的问题
    this.$fromArray(this.$getEl(".el-picker-panel")).forEach(function (item) {
      if (!item.isHidden()) item.hide();
    });
    this.$fromArray(this.$getEl(".el-cascader-menus")).forEach(function (item) {
      if (!item.isHidden()) item.hide();
    });
  },
  mounted() {
    window.$appConfig = {
      ...window.$appConfig,
      ...sysmanConfig
    }
    document.querySelector('title').text = window.$appConfig[window.$appConfig.project].title
    document.querySelector('link[rel=icon]').href = window.$appConfig[window.$appConfig.project].favicon
    bus.$on("Notification", (data) => {
      h(
        "div",
        {
          class: {
            "fant-globalmsg": true,
          },
        },
        [
          h(msgAction, {
            props: {
              data: data,
            },
          }),
        ]
      );
      this.$notify({
        title: data.title,
        dangerouslyUseHTMLString: true,
        iconClass: "el-icon-info color-primary",
        message: msg,
        position: "bottom-right",
        duration: 5000,
      });
    });
  },
});
</script>
