import Message from './message';
import request from '@/utils/request'
import { message, confirm } from 'vue-fant-base-plus'

export function exportExcel(url,data){
    return new Promise(resolve=>{
        request({
            url:url,
            method:'post',
            responseType:'blob',
            data
        }).then((re)=>{
            let blob = new Blob([re], { type: "application/vnd.ms-excel" })
            let url = window.URL.createObjectURL(blob);
            window.location.href = url;
            resolve()
        }).catch((err)=>{
            message({type:'error',message:MSG__.ERROR})
        })
    })
}

export function uuid() {
    var s = [];
    var hexDigits = "0123456789abcdef";
    for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4";  // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = "-";
 
    var uuid = s.join("");
    return uuid;
}

// 导出  method传输方法,url地址，params参数，filename导出的文件名
export function downloadFile(method="get",url,params,filename,callback,type='application/vnd.ms-excel',responseType='arraybuffer'){
    // this.showIndexLoadingWin('正在操作中，请勿关闭或刷新浏览器');
    request({url:url,method: method,data:params,responseType: responseType})
    // this.$http[method](url,params,{responseType:responseType})
    .then(res=>{
        if(res){
            let blob = new Blob([res], { type: type })  
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            // this.closeIndexLoadingWin();
            if(typeof callback == "function"){
                callback();
            }
        }else{
            message({
                showClose:true,
                message:res.message,
                type:'error'
            });
            // this.closeIndexLoadingWin();
        }
    }).catch(err=>{
        // this.closeIndexLoadingWin();
    })
}

export function downloadTemplate(url,fileId,name,downloadParams={},method='get'){	
    // this.showIndexLoadingWin('正在到操作中,请勿关闭或刷新浏览器');
    request({
        url:url,
        method: method,
        data:downloadParams,
        responseType:'arraybuffer',
    }).then(res=>{
        if(res){
            let blob = new Blob([res], { type: 'application/vnd.ms-excel'})  
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob);
            link.download =name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            // this.closeIndexLoadingWin();
        }
    }).catch((err) => {
        // this.closeIndexLoadingWin();
        message({showClose:true, msg: "服务器繁忙，请稍后再试！", type: "error"});
    }); 
}
// 删除  row数据,method方法，successCallback成功回调
export function deleteCurrent(method='delete',url,successCallback,text="删除"){
    confirm({
        msg:`确定要${text}吗？`,
        fn:bool=>{  
            if(bool){
                this.$http[method](url).then(res=>{
                    if(res.ok){
                        message({
                            showClose:true,
                            msg:`${text}成功`,
                            type:'success'
                        })
                        successCallback && successCallback(res);
                    }else{
                        message({
                            showClose:true,
                            message:res.message,
                            type:'error'
                        });
                    }
                }).catch((err) => {
                    message({showClose:true, msg: "服务器繁忙，请稍后再试！", type: "error"});
                });                           
            }
        }
    })
}

export function customReq(method='post',url,params={},successCallback,errorCallback){
    request[method](url,params).then(res=>{
        // // console.log(res)
        if(res.ok){
            successCallback && successCallback(res);
        }else{
            message({
                showClose:true,
                message:res.message,
                type:'error'
            });
            errorCallback && errorCallback();
        }
    })
}

// 去重
export function duplicate(arr,type){
    if(arr.length == 0){
        return arr;
    }else{
        if(type){
            var obj = {}
            var newArr = arr.reduce((cur,next) => {						
                let keyName = next[type];				
                obj[keyName] ? "" : obj[keyName] = true && cur.push(next);
                return cur;
            },[])
            return newArr;
        }else{
            return Array.from(new Set(arr));
        }
    }
}

// 对象是否相等
export function isEqual(obj1, obj2) {
    // 判断类型是否相等
    if (typeof obj1 !== typeof obj2) {
        return false;
    }

    // 如果是基本类型直接比较值
    if (typeof obj1 !== 'object' || obj1 === null || obj2 === null) {
        return obj1 === obj2;
    }

    // 获取两个对象的属性名数组
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // 属性数量不相等，返回false
    if (keys1.length !== keys2.length) {
        return false;
    }

    // 比较每个属性的值
    for (let key of keys1) {
        if (!isEqual(obj1[key], obj2[key])) {
        return false;
        }
    }

    return true;
}