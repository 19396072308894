import request from '@/utils/logRequest'
export default{
  login(name, password, { captcha, captchaId, isPlatformOperate }){
    let url = isPlatformOperate?'/web/admin/login':'/web/user/login'
    let formData = new FormData()
    formData.append('code', name);
    formData.append('pwd', password);
    formData.append('captcha', captcha);
    formData.append('captchaId', captchaId);
    return request({url:url,method:"post",data:formData});
  },
  refresh(tokenJson){
    return request({url:"/auth/token/refresh",method:"post",params:tokenJson});
  },
  changeFirm(id, token){
      return request({url:"/auth/firm/change?firmId="+id+"&token="+token,method:"post"});
  },
  register(registerdata){
      return request({method:"post",url:"/user/register",params:registerdata});
  },
  retrievePwd(data){
      return request({method:"post",url:"/user/forgot/password?telephone="+data.phone+"&newPassword="+data.password+"&verifyPassword="+data.confirmPassword+"&tokenKey="+data.token});
  },
  sendVerification(phone){
      return request({method:"post",url:"/user/send/verification?telephone="+phone});
  },
  checkVerification(verdata){
      return request({method:"post",url:"/user/check/verification/code?telephone="+verdata.phone+"&code="+verdata.code});
  },
  getOnlineTotal(){
    return request({url:"/online/monitoring/count",method:"get"});
  }
}
