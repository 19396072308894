import store from '@/store';
import { request } from '@/utils/request'

/**
 * 获取用户信息
 * @returns 
 */
export const getCurrent = async()=>{
    const re:any = await request.get('/web/common/sysman/user/currentInfo')
    if(re.ok){
        const current = re.data;
        current.roleTypeCode = (store.state as any).user.loginInfo.userType
        if (!current?.staffId) {
          current.staffId = current.roleTypeBizId
        }
        // if (current.roleTypeCode !== 'ADMIN' && (store.state as any).user.identityList?.length === 2) {
        //   current.modelType = 'ALL'
        // }
        const party = (store.state as any).user.identityList.find((item:any) => {
          return item.roleTypeCode === 'PARTYMEMBER'
        }) 
        if (party) {
          current.partyMemberId = party.roleTypeBizId
        }
        store.commit('SET_CURRENT', current)
    }
}


/**
 * 获取Url中参数的值
 * @param {Stirng} name 参数的字段名
 */
 export function getUrlParam(name:string) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var result = window.location.search.substr(1).match(reg);
    if (result != null) return decodeURIComponent(result[2]);
    return null;
  }