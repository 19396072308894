import { createApp } from 'vue'
import entry from '@/boot/entry'
import App from './App.vue'
import store from '@/store'
import router from '@/router'
(window as any).UEDITOR_HOME_URL =  import.meta.env.BASE_URL+"static/ueditor/";
// import startApps from './apps'
/* 以便子系统访问主系统仓库 */
(window as any).$mainStore = store
const app = createApp(App)
app.use(entry).use(store).use(router).mount('#app')

export { app }
