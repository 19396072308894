import { ElMessage } from "element-plus";
import axios, { AxiosRequestConfig } from "axios";
import { bus, ajax } from "vue-fant-base-plus";
import { timeout, BASE_URL } from "./logRequest";
import { Store } from "vuex";
import store from "@/store";
import { ajaxTrackingHttpApi, axiosTrackingHttpApi } from "./tracking";
import { isEqual } from "@/libs/mixin";

declare module 'axios' {
  interface AxiosResponse<T = any> {
    ok: Boolean
    message: string
    moreData: any
    data: T
    pageNo: number
    pageSize: number
    rows: any[]
    totalCount: number
    totalPage: number
  }
  export function create(config?: AxiosRequestConfig): AxiosInstance;
}

const PROD = import.meta.env.VITE_NODE_ENV === "production";
const state = store.state as any

/**
 * 生产环境根据后台版本号替换请求地址
 * @param {string} url
 * @param {Store} store
 */
function replaceUrl(url: string, store: Store<any>) {
  const version = JSON.parse(sessionStorage.getItem("version") || "{}");
  if (store.state.user.token && version && PROD) {
    let header = "";
    if (url.indexOf("http://") != -1) {
      header = "http://";
      url = url.substr(7, url.length - 1);
    } else if (url.indexOf("https://") != -1) {
      header = "https://";
      url = url.substr(8, url.length - 1);
    }
    let fi = url.indexOf("/");
    let se = 0;
    if (fi > -1) {
      se = url.indexOf("/", fi + 1);
    }
    const mname = url.substr(fi + 1, se - fi - 1);
    const char1 = url.substr(0, se);
    const char2 = url.substr(se, url.length - 1);
    if (version[mname]) {
      url = header + char1 + "/v" + version[mname] + char2;
    }
  }
  return url;
}

function hideLoading() {
  setTimeout(() => {
    bus.hideLoading();
  }, 300);
}

function handleLogOutFun() {
  const win = window as any;
  if (win.$mainStore) {
    win.$mainStore.dispatch("LogOut");
  } else {
    store.dispatch("LogOut");
  }
}

let pending: {
  config: Record<string, any>,
  cancle: Function
}[] = []; //声明一个数组用于存储每个ajax请求的取消函数和ajax标识
const cancelToken = axios.CancelToken;
const removeRepeatUrl = (ever: any) => {
  for (let p in pending) {
    // 判断是否存在重复请求
    if (
      pending[p].config &&
      pending[p].config.url === ever.url &&
      pending[p].config.method === ever.method &&
      isEqual(pending[p].config, ever) 
    ) {
      //当当前请求在数组中存在时执行函数体
      pending[p].cancle(); //执行取消操作
      pending.splice(Number(p), 1); //把这条记录从数组中移除
    }
  }
};

// 重复请求的白名单，数组填写接口，例如：/mobile/file/firmfile/*/download
const requestWhiteList: string[] = []

function handleWhiteList(url: string): boolean {
  let urlArr: string[] = []
  if (url.includes('?') || url.includes('&')) {
    urlArr = url.split('?')
    return !requestWhiteList.includes(urlArr[0])
  } else {
    urlArr = url.split('/').filter(Boolean).map(el => /^[A-Za-z]+$/.test(el) ? el : '*')
    const urlStr = urlArr.join('/')
    return !requestWhiteList.includes('/' + urlStr)
  }
}

function handleRestrictRequestsFun(config: AxiosRequestConfig): boolean {
  if (!config.url) {
    return false
  } else if (requestWhiteList.length && handleWhiteList(config.url)) {
    return true
  } else {
    return (config.method === 'put' && config.url.includes('/edit/')) ||
      (config.method === 'post' && config.url.includes('/add'))
  }
}

export const request = axios.create({
  baseURL: BASE_URL, // api的base_url
  timeout, // 请求超时时间
});

// request拦截器
request.interceptors.request.use(
  function (config) {
    if (handleRestrictRequestsFun(config)) {
      //在一个ajax发送前执行一下取消操作
      removeRepeatUrl({
        method: config.method,
        url: config.url,
        params: config.params,
        data: config.data
      });
      // 创建cancleToken和cancle取消请求方法, 每个请求都不一样的哦
      config.cancelToken = new cancelToken(callback => {
        // 自定义唯一标识
        pending.push({
          config: {
            method: config.method,
            url: config.url,
            params: config.params,
            data: config.data
          },
          cancle: callback
        });
      });
    }

    // 非党员，需要把/web/party/替换/web/staff/
    if (config.url?.includes("/web/party/") && state.user.current.roleTypeCode !== 'PARTYMEMBER') {
      config.url = config.url.replace("/web/party/", "/web/staff/");
    }

    config.url = replaceUrl(config.url || "", store);
    (config as any).date = new Date();

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

/**
 * 不需要错误提示的请求路径匹配
 */
const ignoreErrorPaths = [/web\/tracking\/(.*)/] as any[];

function checkIgnoreError(url: string) {
  let r = false as any;
  for (let i in ignoreErrorPaths) {
    const item = ignoreErrorPaths[i];
    r = url.match(item);
    if (r) {
      break;
    }
  }
  return r;
}

// respone拦截器
request.interceptors.response.use(
  (response) => {
    axiosTrackingHttpApi(response);
    if (handleRestrictRequestsFun(response.config)) {
      //在一个ajax响应后再执行一下取消操作，把已经完成的请求从pending中移除
      removeRepeatUrl({
        method: response.config.method,
        url: response.config.url,
        params: response.config.params,
        data: response.config.data
      }); 
    }
    hideLoading();
    return response.data;
  },
  (error) => {
    axiosTrackingHttpApi(error.response);
    hideLoading();
    /* 排除不需要提示的请求路径 */
    if (checkIgnoreError(error.response.config.url)) {
      return;
    }
    if (error.response.status == "401") {
      ElMessage({
        message: "系统登录超时，请重新登录",
        type: "error",
        duration: 3 * 1000,
      });
      handleLogOutFun();
    } else if (String(error.response.status).indexOf("50") == 0) {
      ElMessage({
        message: "服务出了点小故障，请与管理员联系解决",
        type: "error",
        duration: 5 * 1000,
      });
      return Promise.reject(error);
    } else {
      ElMessage({
        message: "网络异常，请稍后重试",
        type: "error",
        duration: 5 * 1000,
      });
      return Promise.reject(error);
    }
  }
);

const myajax = (obj: any) => {
  let opt = Object.assign(obj, {
    timeout,
    beforeRequest: (request: any) => {
      let url = BASE_URL + obj.url;
      url = replaceUrl(url, store);

      // 非党员，需要把/web/party/替换/web/staff/
      if (url.includes("/web/party/") && state.user.current.roleTypeCode !== 'PARTYMEMBER') {
        url = url.replace("/web/party/", "/web/staff/");
      }

      request.url = url;
      return request;
    },
    beforeResponse: (res: any) => {
      ajaxTrackingHttpApi(res);
      hideLoading();
      /* 排除不需要提示的请求路径 */
      if (checkIgnoreError(res.config.url)) {
        return true;
      }
      if (res.status !== "200" && res.status !== 200) {
        if (res.status == "401") {
          ElMessage({
            message: "系统登录超时，请重新登录",
            type: "error",
            duration: 3 * 1000,
          });
          handleLogOutFun();
          return false;
        } else if (String(res.status).indexOf("50") == 0) {
          ElMessage({
            message: "服务出了点小故障，请与管理员联系解决",
            type: "error",
            duration: 5 * 1000,
          });
          return false;
        }
      } else {
        return true;
      }
    },
  });
  ajax(opt);
};

const requestPlugin = {} as Record<string, any>;

requestPlugin.install = (app: any) => {
  app.config.globalProperties.$http = request;
  app.config.globalProperties.$ajax = myajax;
  const downloadFile = (url: string) => {
    url = BASE_URL + url;
    url = replaceUrl(url, store);
    if (url.indexOf("?") != -1) {
      url += `&token=${state.user.token}`;
    } else {
      url += `?token=${state.user.token}`;
    }
    window.location.href = url;
  };
  app.config.globalProperties.$downloadFile = downloadFile;
};

export default requestPlugin;
