import fant from '@/libs/fant-plugins'
import extend from './extend';
//基于各个浏览器和系统的不同，重写了中文字的拼音排序比较
import "./pinyin-sort.js";
/* 组件全局注册 */
import fantImg from "@/components/fantImg.vue";

function Plugins(){}
Plugins.install = (app, router, store)=>{
    /* 初始化公司内部插件 */
    app.use(fant,{store})
    //扩展或重新集合
    app.use(extend);

    /* 全局组件注册 */
    app.component("fantImg", fantImg);
}

export default Plugins